import React from "react"

export default function FrenchOurStory() {
  return (
    <>
      <h1>Notre histoire – Comment ça f'real?</h1>

      <section>
        <p>
          Vous avez découvert les boissons glacées les plus savoureuses de la
          planète! Mais comment tout ça a commencé?
        </p>
      </section>

      <section>
        <p>
          À l'époque, notre fondateur Jim a eu l'idée de concevoir un mélangeur
          capable de préparer des gobelets de pur bonheur, avec du vrai lait et
          des ingrédients honnêtes. En 2003, le premier mélangeur f'real a été
          installé dans un magasin de Tulsa, en Oklahoma.
        </p>
      </section>

      <section>
        <p>
          Nous avons parcouru beaucoup de chemin depuis, mais nos laits frappés
          et nos smoothies demeurent des collations rafraîchissantes et
          savoureuses que vous, oui, VOUS, pouvez mélanger vous-même avec la
          machine f'real : choisissez votre saveur et votre épaisseur, puis
          préparez-vous à f'realer le parfait bonheur.
        </p>
      </section>

      <section>
        <p>
          Nous sommes présents dans plus de 20 000 points de vente dans le monde
          entier : vous ne pouvez pas nous manquer.
        </p>
      </section>
    </>
  )
}
